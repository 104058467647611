module.exports = [{
      plugin: require('/Users/vp/Dropbox/projects/odliva-pets/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-130271852-1"},
    },{
      plugin: require('/Users/vp/Dropbox/projects/odliva-pets/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/vp/Dropbox/projects/odliva-pets/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
